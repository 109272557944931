// Component to render the landing page of the website
import React, { Fragment } from "react";

import "../../app.css";
import FlowersBackground from "../shared components/FlowersBackground";
import AppConfig from "../../AppConfig";
import OrderForm from "./OrderForm";
import ResponsiveContainer from "../shared components/ResponsiveContainer";

import "./orderPage.css";

const config = AppConfig.orderPage;

class OrderPage extends React.Component {
  render() {
    return (
      <Fragment>
        <FlowersBackground />
        <ResponsiveContainer>
          <OrderForm />
        </ResponsiveContainer>
      </Fragment>
    );
  }
}

export default OrderPage;
