// Website footer
import React from "react";
import { Grid, Header, Icon, List, Segment } from "semantic-ui-react";
import ResponsiveContainer from "./ResponsiveContainer";
import AppConfig from "../../AppConfig";
import "./footer.css";

const config = AppConfig.landingPage.footer;

class Footer extends React.Component {
  // TODO: move all the footer content to application configuration file

  render() {
    const socialIcons = config.social.map(({ icon, link, text }) => {
      return (
        <List.Item as="a" href={link} target={"_blank"}>
          <List.Icon name={icon} />
          <List.Content content={text} />
        </List.Item>
      );
    });

    return (
      <Segment
        inverted
        vertical
        padded={"very"}
        className={"footer-background"}
      >
        <ResponsiveContainer>
          <Grid divided inverted stackable>
            <Grid.Row>
              <Grid.Column width={3}>
                <Header inverted as="h4" content={config.heading} />
                <List link inverted>
                  {socialIcons}
                </List>
              </Grid.Column>
              <Grid.Column
                width={13}
                verticalAlign="bottom"
                textAlign={"right"}
              >
                {/*<Header inverted as="h4" content={config.heading} />*/}
                <List inverted verticalAlign="bottom">
                  <List.Item>
                    <List.Content content={config.copyrightNote} />
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </ResponsiveContainer>
      </Segment>
    );
  }
}

export default Footer;
