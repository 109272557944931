// Modified version of Semantic UI Container which fills the whole page width on under-desktop screen sizes
import React from "react";

import "./responsiveContainer.css";

// Values taken from Semantic UI container: https://semantic-ui.com/elements/container.html
const config = {
  largeMonitorBreakpoint: 1127,
  smallMonitorBreakpoint: 933,
  tabletBreakpoint: 723,
  mobileBreakpoint: 600, // not used in Semantic this way
};

const ResponsiveContainer = (props) => {
  return <div className="ui responsive-container">{props.children}</div>;
};

export default ResponsiveContainer;
