import React from "react";
import { BrowserRouter, Route } from "react-router-dom";

import "./app.css";
import "./typography.css";
import LandingPage from "./components/landing page/LandingPage";
import ScrollToTop from "./components/utilities/ScrollToTop";
import Header from "./components/shared components/Header";
import MediaRouter from "./components/media page/MediaRouter";
import OrderPage from "./components/order page/OrderPage";

function App() {
  return (
    <div className="app-root-canvas app-typography">
      <BrowserRouter>
        <ScrollToTop />
        <Route path={"/"} component={Header} />
        <div className="app-content-canvas">
          <Route path={"/"} exact component={LandingPage} />
          <Route path={"/order"} exact component={OrderPage} />
          <MediaRouter />
        </div>
        {/*<Route path={"/"} component={Footer} />*/}
      </BrowserRouter>
    </div>
  );
}

export default App;
