// Component to render the landing page of the website
import React, { Fragment } from "react";
import HeroSection from "./sections/HeroSection";
import Header, { HeaderPadding } from "../shared components/Header";
import ReviewsSection from "./sections/ReviewsSection";
import QrSection from "./sections/QrSection";
import AboutSection from "./sections/AboutSection";
import ExcerptsSection from "./sections/ExcerptsSection";
import Footer from "../shared components/Footer";

import "../../app.css";

class LandingPage extends React.Component {
  render() {
    return (
      <Fragment>
        <div id="home" className="scroll-anchor" />
        <HeroSection />
        <div id="reviews" className="scroll-anchor" />
        <ReviewsSection />
        <div id="qr" className="scroll-anchor" />
        <QrSection />
        <div id="about" className="scroll-anchor" />
        <AboutSection />
        <div id="excerpts" className="scroll-anchor" />
        <ExcerptsSection />
        <Footer />
      </Fragment>
    );
  }
}

export default LandingPage;
