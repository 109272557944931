// Reducer which handles the logic of application header page
import merge from "deepmerge";

import { headerActionTypes } from "../actions/headerActions";

const INITIAL_STATE = {
  isMobileSidebarOpen: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case headerActionTypes.OPEN_MOBILE_SIDEBAR: {
      // Here we update sidebar state to open
      const update = {
        isMobileSidebarOpen: true,
      };
      return merge(state, update);
    }
    case headerActionTypes.CLOSE_MOBILE_SIDEBAR: {
      // Here we update sidebar state to closed
      const update = {
        isMobileSidebarOpen: false,
      };
      return merge(state, update);
    }
    default:
      return state;
  }
};
