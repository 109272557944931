// About section of the landing page
import React, { Fragment } from "react";
import {
  Button,
  Grid,
  Header,
  Responsive,
  Segment,
  Transition,
  Visibility,
  Image,
  Card,
} from "semantic-ui-react";

import ResponsiveContainer from "../../shared components/ResponsiveContainer";
import "./aboutSection.css";
import AppConfig from "../../../AppConfig";
import HeaderBubble from "../elements/HeaderBubble";

const config = AppConfig.landingPage.aboutSection;

class AboutSection extends React.Component {
  renderTextSegment = () => {
    return (
      <Fragment>
        <HeaderBubble text={config.heading} />
        <Segment padded className="about-section-content-text paragraph-block">
          {config.text}
        </Segment>
      </Fragment>
    );
  };

  renderImageSegment = () => {
    return (
      <Fragment>
        <Segment basic className="about-section-content-author-image-wrapper">
          <Responsive minWidth={Responsive.onlyTablet.minWidth}>
            <Image
              size={"large"}
              src={config.authorImage}
              className="about-section-content-author-image"
            />
          </Responsive>
          {/* Image is made smaller on mobile */}
          <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
            <Image
              size={"medium"}
              src={config.authorImage}
              className="about-section-content-author-image"
            />
          </Responsive>
        </Segment>
      </Fragment>
    );
  };

  render() {
    const contentSegmentColumnWidth = 9;
    const imageSegmentColumnWidth = 7;

    // Get segments
    const firstSegment = this.renderTextSegment();
    const secondSegment = this.renderImageSegment();

    // Get content layout
    const contentGridImageRight = (
      <Grid
        verticalAlign={"top"}
        stackable
        className={"no-excess-padding-grid about-section-content"}
      >
        <Grid.Row>
          <Grid.Column width={imageSegmentColumnWidth}>
            {secondSegment}
          </Grid.Column>
          <Grid.Column width={contentSegmentColumnWidth}>
            {firstSegment}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );

    // For desktop and tablet, we use left- or right-image layout depending on the "imageOnTheLeft" prop.
    let contentGridDesktop = (
      <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        {contentGridImageRight}
      </Responsive>
    );
    // For mobile, we use left-image layout, so that when it gets stacked, the image always gets on top.
    let contentGridMobile = (
      <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
        {contentGridImageRight}
      </Responsive>
    );

    return (
      <Fragment>
        <div className="about-section">
          <div className="about-section-anchor" />
          <div className="about-section-padding">
            <ResponsiveContainer>
              <div className="about-section-content">
                {contentGridDesktop}
                {contentGridMobile}
              </div>
            </ResponsiveContainer>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AboutSection;
