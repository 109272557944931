// Renders a single review card from reviews section of the landing page
import React, { Fragment } from "react";
import {
  Card,
  Divider,
  Feed,
  Image,
  Label,
  Modal,
  Responsive,
} from "semantic-ui-react";

import "./reviewCard.css";

class ReviewCard extends React.Component {
  render() {
    const { image, name, meta, message } = this.props;

    return (
      <Fragment>
        <Card className={"review-card"}>
          <Card.Content textAlign={"center"}>
            {/* Image is centered above the card header on desktop */}
            <Responsive minWidth={Responsive.onlyTablet.minWidth}>
              <Image
                size={"small"}
                src={image}
                className={"review-card-image"}
              />
            </Responsive>
            {/* Image is made smaller on mobile */}
            <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
              <Image
                // floated={"left"}
                size={"tiny"}
                src={image}
                className={"review-card-image"}
              />
            </Responsive>
            <Card.Header>{name}</Card.Header>
            <Card.Meta>{meta}</Card.Meta>
            <Divider />
            <Card.Description className={"review-card-message"}>
              {message}
            </Card.Description>
          </Card.Content>
        </Card>
      </Fragment>
    );
  }
}

export default ReviewCard;
