// Displays Semantic UI header in a white highlighted bubble. Used for Landing Page headers.
import React, { Fragment } from "react";
import { Header } from "semantic-ui-react";
import "./headerBubble.css";

class HeaderBubble extends React.Component {
  render() {
    const text = this.props.text;

    return (
      <div className="header-bubble-wrapper golden-shadow-highlight">
        <div className="header-bubble">
          <Header size={"large"} content={text} />
        </div>
      </div>
    );
  }
}

export default HeaderBubble;
