// Component to display source media after the QR code link
import React, { Fragment } from "react";
import { Image } from "semantic-ui-react";

import "./MediaPage.css";

class MediaPage extends React.Component {
  renderBackground = () => {
    return (
      <img
        src={require("../landing page/resources/hero_background.png")}
        className="media-page-background"
        alt=""
      />
    );
  };

  renderMedia = () => {
    const { mediaSrc } = this.props;

    return (
      <div className="media-page-media-block paragraph-block">
        <Image src={mediaSrc} fluid className="media-page-media" />
      </div>
    );
  };

  render() {
    return (
      <Fragment>
        <div className="media-page-media-container">
          {this.renderBackground()}
          {this.renderMedia()}
        </div>
      </Fragment>
    );
  }
}

export default MediaPage;
