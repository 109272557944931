// Section of the landing page explaining the use of QR codes
import React, { Fragment } from "react";
import {
  Button,
  Grid,
  Header,
  Responsive,
  Segment,
  Transition,
  Visibility,
  Image,
  Card,
} from "semantic-ui-react";

import ResponsiveContainer from "../../shared components/ResponsiveContainer";
import "./qrSection.css";
import AppConfig from "../../../AppConfig";
import FlowersBackground from "../../shared components/FlowersBackground";
import HeaderBubble from "../elements/HeaderBubble";

const config = AppConfig.landingPage.qrSection;

class QrSection extends React.Component {
  renderTextSegment = () => {
    return (
      <Fragment>
        <HeaderBubble text={config.heading} />
        <Segment padded className="qr-section-content-text paragraph-block">
          {config.text}
        </Segment>
      </Fragment>
    );
  };

  renderImageSegment = () => {
    return (
      <Fragment>
        <Segment basic className={"qr-section-content-qr-image-wrapper"}>
          <Responsive minWidth={Responsive.onlyTablet.minWidth}>
            <Image
              size={"medium"}
              src={config.qrImage}
              className="qr-section-content-qr-image"
            />
          </Responsive>
          {/* Image is made smaller on mobile */}
          <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
            <Image
              size={"small"}
              src={config.qrImage}
              className="qr-section-content-qr-image"
            />
          </Responsive>
        </Segment>
      </Fragment>
    );
  };

  render() {
    const contentSegmentColumnWidth = 11;
    const imageSegmentColumnWidth = 5;

    // Get segments
    const firstSegment = this.renderTextSegment();
    const secondSegment = this.renderImageSegment();

    // Get content layout
    const contentGridImageRight = (
      <Grid
        verticalAlign={"middle"}
        stackable
        className={"no-excess-padding-grid qr-section-content"}
      >
        <Grid.Row>
          <Grid.Column width={contentSegmentColumnWidth}>
            {firstSegment}
          </Grid.Column>
          <Grid.Column width={imageSegmentColumnWidth} textAlign={"center"}>
            {secondSegment}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );

    // For desktop and tablet, we use left- or right-image layout depending on the "imageOnTheLeft" prop.
    let contentGridDesktop = (
      <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        {contentGridImageRight}
      </Responsive>
    );
    // For mobile, we use left-image layout, so that when it gets stacked, the image always gets on top.
    let contentGridMobile = (
      <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
        {contentGridImageRight}
      </Responsive>
    );

    return (
      <Fragment>
        <div className="qr-section">
          <div className="qr-section-anchor" />
          <FlowersBackground />
          <div className="qr-section-padding">
            <ResponsiveContainer>
              <div className="qr-section-content">
                {contentGridDesktop}
                {contentGridMobile}
              </div>
            </ResponsiveContainer>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default QrSection;
