// Displays expandable card with a single excerpt from the book.
import React, { Fragment } from "react";
import {
  Button,
  Card,
  Divider,
  Image,
  Modal,
  Responsive,
} from "semantic-ui-react";
import AppConfig from "../../../AppConfig";

import "./excerptCard.css";

const config = AppConfig.landingPage.excerptsSection;

class ExcerptCard extends React.Component {
  state = {
    isFullTextOpen: false,
  };

  openFullText = () => {
    this.setState({
      isFullTextOpen: true,
    });
  };

  closeFullText = () => {
    this.setState({
      isFullTextOpen: false,
    });
  };

  renderFullTextModal = () => {
    const { heading, fullText } = this.props;

    return (
      <Fragment>
        <Modal
          centered={false}
          closeIcon
          open={this.state.isFullTextOpen}
          onClose={this.closeFullText}
          onOpen={this.openFullText}
          trigger={
            <Button
              secondary
              content={config.readMoreButtonText}
              className="excerpt-card-button"
            />
          }
          size={"fullscreen"}
          className="excerpt-card-modal"
        >
          <Modal.Header>Уривок: {heading}</Modal.Header>
          <Modal.Content scrolling>{fullText}</Modal.Content>
        </Modal>
      </Fragment>
    );
  };

  render() {
    const { heading, shortText } = this.props;

    return (
      <Fragment>
        <Card className="excerpt-card">
          <Card.Content textAlign={"center"}>
            <Card.Header>{heading}</Card.Header>
            <Divider />
            <Card.Description>{shortText}</Card.Description>
            {this.renderFullTextModal()}
          </Card.Content>
        </Card>
      </Fragment>
    );
  }
}

export default ExcerptCard;
