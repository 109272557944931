// Fills parent element with a flower pattern. Used for backgrounds.
import React, { Fragment } from "react";
import AppConfig from "../../AppConfig";
import "./flowersBackground.css";

const config = AppConfig.landingPage.heroSection;

class FlowersBackground extends React.Component {
  render() {
    return (
      <Fragment>
        <img src={config.backgroundImage} className="flowers-background" />
      </Fragment>
    );
  }
}

export default FlowersBackground;
