// Hero section of the landing page
import React, { Fragment } from "react";
import {
  Button,
  Grid,
  Header,
  Responsive,
  Segment,
  Transition,
  Visibility,
  Image,
} from "semantic-ui-react";

import ResponsiveContainer from "../../shared components/ResponsiveContainer";
import "./heroSection.css";
import AppConfig from "../../../AppConfig";
import FlowersBackground from "../../shared components/FlowersBackground";
import { Link } from "react-router-dom";

const config = AppConfig.landingPage.heroSection;

class HeroSection extends React.Component {
  renderTitleBlock = () => {
    return (
      <Fragment>
        <div className="hero-section-title-block-container">
          <Image
            src={config.titleImage}
            className="hero-section-image-shadow"
          />
          <Header
            size={"large"}
            className="red-stone-text hero-section-title-text"
          >
            {config.authorText}
          </Header>
          <Button size={"huge"} as={Link} primary to={config.button.to}>
            {config.button.text}
          </Button>
        </div>
      </Fragment>
    );
  };

  renderArtBlock = () => {
    return (
      <Fragment>
        <div className="hero-section-art-block-container">
          <div className="hero-section-art-image-container">
            <Image
              fluid
              src={config.artImage}
              className="hero-section-image-shadow"
            />
          </div>
        </div>
      </Fragment>
    );
  };

  render() {
    return (
      <Fragment>
        <div className="hero-section">
          <div className="hero-section-anchor" />
          <FlowersBackground />
          <div className="hero-section-padding">
            <ResponsiveContainer>
              {this.renderTitleBlock()}
              {this.renderArtBlock()}
            </ResponsiveContainer>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default HeroSection;
