// Reviews section of the landing page
import React, { Fragment } from "react";

import "./reviewsSection.css";
import ReviewCard from "../elements/ReviewCard";
import { Card, Header, Segment } from "semantic-ui-react";
import ResponsiveContainer from "../../shared components/ResponsiveContainer";
import HeaderBubble from "../elements/HeaderBubble";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import AppConfig from "../../../AppConfig";

const config = AppConfig.landingPage.reviewsSection;

class ReviewsSection extends React.Component {
  renderReviewsGrid = () => {
    const reviewCards = config.cards.map((cardData, index) => {
      return <ReviewCard key={index} {...cardData} />;
    });

    return (
      <Card.Group centered stackable itemsPerRow={3}>
        {reviewCards}
      </Card.Group>
    );
  };

  render() {
    return (
      <Fragment>
        <div className="reviews-section">
          <div className="reviews-section-padding">
            <ResponsiveContainer>
              <HeaderBubble text={config.heading} />
              <Divider hidden className="thin-horizontal-divider" />
              {this.renderReviewsGrid()}
            </ResponsiveContainer>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ReviewsSection;
