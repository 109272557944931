import React, { Fragment } from "react";
import { Field as ReduxFormField, reduxForm } from "redux-form";
import { Button, Divider, Form, Header, Segment } from "semantic-ui-react";
import AppConfig from "../../AppConfig";
import AWSLambdaEmailer from "../../apis/AWSLambdaEmailer";

import "./orderPage.css";

const config = AppConfig.orderPage;

const sendEmail = async (emailRecepients, emailCC, emailSubject, emailText) => {
  const response = await AWSLambdaEmailer.post("/send", {
    toEmails: emailRecepients,
    ccEmails: emailCC,
    subject: emailSubject,
    message: emailText,
  }).catch((error) => {
    console.error(`Error sending contact form email: ${error}`);
  });
};

class OrderForm extends React.Component {
  state = {
    is_sent: false,
  };

  onSubmit = (formValues) => {
    let { name, email, phone, comment, promo, postal, city } = formValues;

    const emailRecepients = ["toindiabook@gmail.com"];
    const emailCC = ["yaruk.ssa@gmail.com"];
    let emailSubject = `Замовлення книги – ${name}`;
    if (promo) {
      emailSubject = `Замовлення книги – ${name} [PROMO: ${promo}]`;
    }
    if (comment === undefined) {
      comment = "";
    }
    let emailText = `${name}\n${email}\n${phone}\n\nCity: ${city}\nNova Poshta: ${postal}\n\n${comment}`;
    if (promo) {
      emailText = `PROMO: ${promo}\n\n${emailText}`;
    }

    sendEmail(emailRecepients, emailCC, emailSubject, emailText);

    this.setState({ is_sent: true });
  };

  renderError = ({ error, touched }) => {
    if (touched && error) {
      return (
        <div className={"ui error message"}>
          <div className={"header"}>{error}</div>
        </div>
      );
    }
  };

  renderInputField = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
  }) => {
    const errorContent =
      error && touched
        ? {
            content: error,
            pointing: "below",
          }
        : null;

    return (
      <Form.Field>
        <label>{label}</label>
        <Form.Input
          {...input}
          autoComplete={"false"}
          placeholder={placeholder}
          error={errorContent}
        />
      </Form.Field>
    );
  };

  renderInputArea = ({
    input,
    label,
    placeholder,
    meta: { touched, error },
  }) => {
    const errorContent =
      error && touched
        ? {
            content: error,
            pointing: "below",
          }
        : null;

    return (
      <Form.Field>
        <label>{label}</label>
        <Form.TextArea
          style={{ minHeight: 100 }}
          rows={5}
          {...input}
          autoComplete={"false"}
          placeholder={placeholder}
          error={errorContent}
        />
      </Form.Field>
    );
  };

  render() {
    const formHeader = (
      <Fragment>
        <Header
          content={config.orderPreTextHeaderText}
          className={"order-form-header"}
        />
        {config.orderPreText}
        <Divider hidden />
      </Fragment>
    );

    let formOrConfirmationText = null;

    if (this.state.is_sent) {
      formOrConfirmationText = (
        <Segment padded className="paragraph-block order-form">
          <Header
            className={"order-form-success-message"}
            content={config.orderConfirmedText}
          />
        </Segment>
      );
      return formOrConfirmationText;
    } else {
      formOrConfirmationText = (
        <Form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <ReduxFormField
            name={config.form.nameField.name}
            component={this.renderInputField}
            label={config.form.nameField.title}
            placeholder={config.form.nameField.placeholder}
            validate={config.form.nameField.errorValidator}
          />
          <ReduxFormField
            name={config.form.emailField.name}
            component={this.renderInputField}
            label={config.form.emailField.title}
            placeholder={config.form.emailField.placeholder}
            validate={config.form.emailField.errorValidator}
          />
          <ReduxFormField
            name={config.form.phoneField.name}
            component={this.renderInputField}
            label={config.form.phoneField.title}
            placeholder={config.form.phoneField.placeholder}
            validate={config.form.phoneField.errorValidator}
          />
          <ReduxFormField
            name={config.form.cityField.name}
            component={this.renderInputField}
            label={config.form.cityField.title}
            placeholder={config.form.cityField.placeholder}
            validate={config.form.cityField.errorValidator}
          />
          <ReduxFormField
            name={config.form.postalField.name}
            component={this.renderInputField}
            label={config.form.postalField.title}
            placeholder={config.form.postalField.placeholder}
            validate={config.form.postalField.errorValidator}
          />
          <ReduxFormField
            name={config.form.promoField.name}
            component={this.renderInputField}
            label={config.form.promoField.title}
            placeholder={config.form.promoField.placeholder}
            validate={config.form.promoField.errorValidator}
          />
          <ReduxFormField
            name={config.form.commentField.name}
            component={this.renderInputArea}
            label={config.form.commentField.title}
            placeholder={config.form.commentField.placeholder}
            validate={config.form.commentField.errorValidator}
          />
          <Button primary content={config.form.sendButton.text} />
        </Form>
      );
    }

    return (
      <Fragment>
        <Segment padded className="paragraph-block order-form">
          {formHeader}
          {formOrConfirmationText}
        </Segment>
      </Fragment>
    );
  }
}

export default reduxForm({
  form: "order",
})(OrderForm);
