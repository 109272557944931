// Component to render the main menu of the website landing page
import React, { Fragment } from "react";
import {
  Button,
  Divider,
  Icon,
  Menu,
  Responsive,
  Sidebar,
  Transition,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { scroller } from "react-scroll";

import "./header.css";
import ResponsiveContainer from "./ResponsiveContainer";
import { connect } from "react-redux";
import {
  closeMobileSidebar,
  openMobileSidebar,
} from "../../actions/headerActions.js";
import AppConfig from "../../AppConfig";

const config = AppConfig.header;

class SharedHeaderMethods {
  static isMenuLinkActive = () => {
    return false;
  };

  static renderLogoText = () => {
    // TODO: Style based on landing page position (logo-text-inverted when on top)

    return (
      <Fragment>
        <a href={"/"} style={{ display: "block" }}>
          <div className="logo-text">
            <span className="logo-text-red">В Індію </span>
            <span className="logo-text-curry"> на весілля</span>
          </div>
        </a>
      </Fragment>
    );
  };

  /**
   *  Builds a single JSX from all menu items.
   */
  static renderMenuItems = (extraOnClickAction = null) => {
    return config.menuItems.map((menuItem) => {
      return (
        <Menu.Item
          key={menuItem.key}
          name={menuItem.name}
          as={Link}
          to={menuItem.to}
          activeClassName="active"
          exact
          strict
          isActive={SharedHeaderMethods.isMenuLinkActive} // TODO: put check function in config // TODO: add extra active check logic to detect active anchor link (otherwise Router treats all landing page anchor links as active menu elements)
          onClick={extraOnClickAction}
          className="header-menu-item"
        />
      );
    });
  };
}

class DesktopHeader extends React.Component {
  render() {
    return (
      <div className="header-z-index">
        <Menu
          fixed={"top"}
          borderless
          className="header-menu-background-colored"
        >
          <ResponsiveContainer>
            <Menu.Item as={"div"} to={"/"} fitted={"horizontally"}>
              {SharedHeaderMethods.renderLogoText()}
            </Menu.Item>
            <Menu secondary floated={"right"}>
              {SharedHeaderMethods.renderMenuItems()}
            </Menu>
            <Menu.Item
              // fitted={"horizontally"}
              className="header-action-button"
            >
              <Button primary as={Link} to={config.actionButton.to}>
                {config.actionButton.text}
              </Button>
            </Menu.Item>
          </ResponsiveContainer>
        </Menu>
      </div>
    );
  }
}

class _MobileHeader extends React.Component {
  componentWillUnmount() {
    // Close sidebar if the mobile header goes away (for example when we switch to desktop header)
    this.closeSidebar();
  }

  openSidebar = () => {
    this.props.openMobileSidebar();
  };

  closeSidebar = () => {
    this.props.closeMobileSidebar();
  };

  renderSidebarOverlay = () => {
    const { isMobileSidebarOpen } = this.props;

    return (
      <Transition.Group
        animation={"fade"}
        duration={config.mobileSidebarAnimationDuration}
      >
        {isMobileSidebarOpen && (
          <Fragment>
            <div
              className="header-mobile-sidebar-overlay"
              onClick={this.closeSidebar}
            />
            <BodyScrollDisabler />
          </Fragment>
        )}
      </Transition.Group>
    );
  };

  renderSidebar = () => {
    const { isMobileSidebarOpen } = this.props;

    return (
      <Fragment>
        <Sidebar
          direction={"top"}
          visible={isMobileSidebarOpen}
          animation={"overlay"}
          className="header-mobile-sidebar"
        >
          <div className="header-mobile-sidebar-content">
            <Menu compact vertical fluid className="header-mobile-menu-rounded">
              {SharedHeaderMethods.renderMenuItems(this.closeSidebar)}
            </Menu>
            <Menu secondary compact vertical fluid>
              <Menu.Item fitted={"horizontally"}>
                <Button
                  primary
                  fluid
                  as={Link}
                  to={config.actionButton.to}
                  onClick={this.closeSidebar}
                >
                  {config.actionButton.text}
                </Button>
              </Menu.Item>
            </Menu>
          </div>
        </Sidebar>
        {this.renderSidebarOverlay()}
      </Fragment>
    );
  };

  renderSidebarToggleButton = () => {
    const { isMobileSidebarOpen } = this.props;

    const onClickAction = isMobileSidebarOpen
      ? this.closeSidebar
      : this.openSidebar;

    return (
      <Menu.Item onClick={onClickAction}>
        {isMobileSidebarOpen ? (
          <Icon size={"large"} fitted name={"close"} className="sidebar-icon" />
        ) : (
          <Icon
            size={"large"}
            fitted
            name={"sidebar"}
            className="sidebar-icon"
          />
        )}
      </Menu.Item>
    );
  };

  renderHeader = () => {
    // const { isMobileSidebarOpen } = this.props;

    return (
      <Fragment>
        <Menu
          fixed={"top"}
          borderless
          className="header-mobile-z-index header-menu-background-colored"
        >
          <ResponsiveContainer>
            <Menu.Item as={"div"} to={"/"} fitted={"horizontally"}>
              {SharedHeaderMethods.renderLogoText()}
            </Menu.Item>
          </ResponsiveContainer>
          <Menu secondary floated={"right"}>
            {this.renderSidebarToggleButton()}
          </Menu>
        </Menu>
      </Fragment>
    );
  };

  render() {
    return (
      <Fragment>
        {this.renderSidebar()}
        {this.renderHeader()}
      </Fragment>
    );
  }

  static mapStateToProps = (state) => {
    const { isMobileSidebarOpen } = state.header;
    return {
      isMobileSidebarOpen,
    };
  };
}
const MobileHeader = connect(_MobileHeader.mapStateToProps, {
  openMobileSidebar,
  closeMobileSidebar,
})(_MobileHeader);

// Empty component which disables body scrolling when it's mounted. To be used inside mobile sidebar.
class BodyScrollDisabler extends React.Component {
  className = "header-mobile-sidebar-body-scroll-disabled";

  componentDidMount() {
    this.enableBodyScroll();
  }

  componentWillUnmount() {
    this.disableBodyScroll();
  }

  enableBodyScroll = () => {
    document.body.classList.add(this.className);
  };

  disableBodyScroll = () => {
    document.body.classList.remove(this.className);
  };

  render() {
    return <Fragment />;
  }
}

class Header extends React.Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { state } = this.props.location;
    if (state && state.fromOtherPage) {
      scroller.scrollTo(state.goTo, {
        duration: 800,
        delay: 100,
        smooth: "easeInOutQuart",
      });
    }
  }

  render() {
    return (
      <Fragment>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <DesktopHeader />
        </Responsive>
        <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
          <MobileHeader />
        </Responsive>
      </Fragment>
    );
  }
}
export default Header;

/**
 * Component that takes enough vertical space to compensate for the height of the header.
 * As header is attached to the top of the page, it is floating on top of the content and can cover it
 * in some cases. Add HeaderPadding component to the beginning of the page to compensate for it.
 * @constructor
 */
export const HeaderPadding = () => {
  // TODO: Make it sample the actual height of the Header component and adjust accordingly.
  return <div style={{ position: "relative", height: "4rem" }} />;
};
