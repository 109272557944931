// Global JSON configuration file with website content and configuration variables

// Fetch interval for API Demo (how often camera image and occupancy should be re-fetched)
import React, { Fragment } from "react";
import { Divider, Header } from "semantic-ui-react";

export default {
  header: {
    mobileSidebarAnimationDuration: 400,
    menuItems: [
      {
        key: "home",
        name: "Головна",
        to: {
          pathname: "/",
          state: {
            fromOtherPage: true,
            goTo: "home",
          },
        },
      },
      {
        key: "reviews",
        name: "Відгуки",
        to: {
          pathname: "/",
          state: {
            fromOtherPage: true,
            goTo: "reviews",
          },
        },
      },
      {
        key: "qr",
        name: "Про книгу",
        to: {
          pathname: "/",
          state: {
            fromOtherPage: true,
            goTo: "qr",
          },
        },
      },
      {
        key: "about",
        name: "Про автора",
        to: {
          pathname: "/",
          state: {
            fromOtherPage: true,
            goTo: "about",
          },
        },
      },
      {
        key: "excerpts",
        name: "Уривки",
        to: {
          pathname: "/",
          state: {
            fromOtherPage: true,
            goTo: "excerpts",
          },
        },
      },
    ],
    actionButton: {
      text: "Замовити",
      to: {
        pathname: "/order",
        state: {
          fromOtherPage: true,
          goTo: "/test",
        },
      },
    },
  },
  landingPage: {
    heroSection: {
      authorText: "Мандрівничий епос від Ярослава Головенька",
      button: {
        text: "Замовити",
        to: {
          pathname: "/order",
          state: {
            fromOtherPage: true,
            goTo: "/test",
          },
        },
      },
      titleImage: require("./components/landing page/resources/hero_image_title.png"),
      artImage: require("./components/landing page/resources/hero_image_art.png"),
      backgroundImage: require("./components/landing page/resources/hero_background.png"),
    },
    reviewsSection: {
      heading: "Відгуки",
      cards: [
        {
          image: require("../src/components/landing page/resources/reviewers/review_what_is_love.jpg"),
          name: "What is Love Німак",
          meta: "",
          message: (
            <Fragment>
              <p>
                Мені як перфекціоністу і раціоналісту дуже зайшла ця книга! Все
                по порядку, все "витримано", все в стилі. Хаос відсутній. Ура!
              </p>
              <p>
                Багато мінімалізму та конструктивізму. Дізнався багато нового і,
                навіть загорівся поїхати в Індію. Так, я мушу то зробити.
              </p>
              <p>
                А ще вона переповнена пригодами, креативом та... лайфхаками!
                Дуже мене відволікала від основної роботи. Автор спасибі!
              </p>
              <p>Рекомендую цю книгу! Особливо рекомендую творчим людям.</p>
            </Fragment>
          ),
        },
        {
          image: require("../src/components/landing page/resources/reviewers/review_andriana_lukovets.jpg"),
          name: "Андріана Луковець",
          meta: "",
          message: (
            <Fragment>
              <p>
                Подорожі - одна із найкращих можливостей для росту і розвитку
                особистості, але на жаль, вони недоступні нам в такому масштабі,
                щоб можна було пізнати весь світ. В такій ситуації саме книга
                дозволяє обійти всі обмеження і з головою поринути в інший світ.
                А Індія - це насправді зовсім інший світ.
              </p>
              <p>
                Секрет цієї книги в справжності та відвертості, це дуже легкий і
                приємний для душі нон-фікшн: місцями я дико обурювалась, місцями
                гиготіла вголос.
              </p>
              <p>
                Найприкольніша в ній мова - вона жива, буденна, справжня. Автор
                знайомить вас із різного спектру словами, починаючи від його
                улюбленого «штріха-чувачка» і закінчуючи «підвищеним рівнем
                ентропії в кімнаті». Як на мене - чистий кайф!
              </p>
              <p>
                А герої, настільки вдало підібрана компанія і типажі людей, що
                там, здається, є всі можливі варіанти. От, скажімо, інколи в
                мене було враження, що там про мене, мою любов до планування і
                фанатичної ходьби пішки, але ніт, в Індії я була тільки на
                сторінках цієї книги.
              </p>
              <p>
                Мушу попередити спойлером, що після прочитання у вас перед очима
                ще кілька днів буде пішохідний неперехід, торги по-індійськи і
                «прекрасний» підхід індусів до вирішення проблеми сміття, мене
                от досі бісить.
              </p>
              <p>
                Одним словом, немає коли пояснювати, беріть і читайте, краще
                читається з великою чашкою чаю чи келихом чогось більш
                прекрасного в руках.
              </p>
            </Fragment>
          ),
        },
        // {
        //   image: require("../src/components/landing page/resources/reviewers/gandhi.jpg"),
        //   name: "Reviewer Name",
        //   meta: "A trustful source",
        //   message:
        //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        // },
      ],
    },
    qrSection: {
      heading: "Про книгу",
      text: (
        <Fragment>
          <p>
            Шановний читачу або шановна читачко! книга, яку ви щойно відкрили,
            це не лише сухий текст. Тут зібрані фото та відео з тієї самої
            подорожі. Вони зроблені без професійного освітлення, а також
            режисера, костюмера, гримера, стиліста та арт-директора. Але ж це
            лише додає їм справжності.
          </p>
          <p>
            Подивитися на них можна, відсканувавши QR-коди зі сторінок книги.
            Тож тримайте свій смартфон поруч.
          </p>
          <p>
            Емоційних подорожей! До речі, цей QR код – також є активним
            посиланням.
          </p>
        </Fragment>
      ),
      qrImage: require("./components/landing page/resources/youtube_channel_qr.png"),
    },
    aboutSection: {
      heading: "Від автора",
      text: (
        <Fragment>
          <p>
            Подорожі… Це ж не лише про споглядання історичних пам’яток та
            визначних місць. Або прогулянки центром старих європейських міст.
            Подорожі — це більш багатогранна матерія. Це і традиції, і побут, і
            логістика в містах, і їжа, і повсякденне життя та поведінка людей… І
            чим більше уся ця екосистема відрізняється від звичної нам, тим
            більше враження вона справляє та яскравіше закарбовується у пам’яті.
          </p>
          <p>
            До таких висновків я дійшов лише у свої 28 років. Ця книга також
            стала частиною цього усвідомлення.
          </p>
          <p>
            Для того, щоб відчути та в повній мірі насолодитися новим місцем,
            щоб на пам’ять залишилося щось більше, ніж просто фото із Ейфелевою
            вежею, потрібні емоції. Емоції можуть бути як позитивними, так і
            негативними. Але вони викарбовують події у нашій пам’яті. Банальне
            споживання їжі дарує нам певні емоції. Їжа сумнівної якості – це
            також емоції. Можливо, навіть яскравіші за абсолютним значенням
            інтенсивності. Всім хороших емоцій при прочитанні цієї книги!
          </p>
        </Fragment>
      ),
      authorImage: require("./components/landing page/resources/author_photo.png"),
    },
    excerptsSection: {
      heading: "Уривки",
      readMoreButtonText: "Читати далі...",
      excerpts: [
        {
          heading: "Свята ватра",
          shortText:
            "Оцінивши обстановочку ми вирішили розпочати пошуки нашого автобуса. Пішли до найближчих платформ. Чимось мені то нагадало  автовокзал в Луцьку, не знаю чого саме там, але така от асоціація. Освітлення на платформах не було, а особливого шарму прилеглій території додавав вогонь який горів по іншу сторону платформи метрах в восьмидесяти-ста від нас...",
          fullText: (
            <Fragment>
              <p>
                Оцінивши обстановочку, ми вирішили розпочати пошуки нашого
                автобуса. Пішли до найближчих платформ. Чимось мені то нагадало
                автовокзал в Луцьку, не знаю, чого саме там, але така-от
                асоціація. Освітлення на платформах не було, а особливого шарму
                прилеглій території додавав вогонь, який горів з іншого боку
                платформи, метрів шістдесят від нас. Ну як вогонь… Вогнище,
                висотою – як автобус і навіть трішки вище, здоровенне таке
                вогнище просто на платформі автовокзалу. Якщо в когось виникло
                питання, як же я так спритно оцінив висоту полум’я, то відповім
                – простіше ніж можна подумати, поруч із полум’ям тупо стояв
                автобус. Такий великий міжміський автобус, не якийсь куций
                «Богдан» чи «Еталон», а повноцінний, повнорозмірний автобус.
                Зліва від вогню. Здавалося, що він там запаркований взагалі
                впритул, а справа, трохи далі був, ще один. Біля самого вогнища
                тусувалися якісь місцеві мужички. Мені було дико цікаво, що ж
                вони там палять таке.
              </p>
              <p>
                – Я, как человек, который учился в России в девяностых,
                базируясь на своем опыте, скажу, что если посреди темной
                автобусной платформы горит двухметровый костер, а вокруг него
                какие-то мужики, то ближе лучше не подходить.
              </p>
              <p>
                У цей момент Ілона розгадала загадку локації нашого автобуса,
                який, як виявилося, мав би розташовуватися з протилежного боку
                будівлі метро. Обігнувши її, нам відкрилася дещо інша картина.
                Набагато більше народу снувало вулицями. А зовсім малесенький
                вогник запаленого сміття спокійненько палахкотів під бордюркою.
                Яке-не-яке вуличне освітлення розрізало нічну темряву.
              </p>
              <p>
                – Дивіться, там через дорогу офіс агенції подорожей, через яку я
                купувала нам квитки, – з відчуттям перемоги повідомила нам
                Ілона.
              </p>
              <p>
                Офісом, власне, був кіоск, вбудований у фасад будівлі, зі столом
                і кріслом всередині. Працівник узяв наші роздруковані квитки і
                подивився на них. Гукнув свого помічника, індійця-підлітка, і
                сказав, щоб той провів нас до автобуса. Автобус був
                припаркований через дорогу. Там у ряд вишикувалися й інші
                автобуси, благо дорога була досить широка. Ззовні він здавався
                доволі цивільним.
              </p>
            </Fragment>
          ),
        },
        {
          heading: "Гроші на вітер",
          shortText:
            "Ще одним цікавим спостереженням було те, що люди, танцюючи, деколи кидають в повітря купюри. Зазвичай десятирупієві. Ці купюри підбирають якійсь діти, явно не з гостей на весіллі...",
          fullText: (
            <Fragment>
              <p>
                Ще одним цікавим спостереженням було те, що люди, танцюючи,
                деколи кидаюли в повітря купюри. Зазвичай десятирупієві. Ці
                купюри підбирали якісь діти, явно не з гостей на весіллі.
              </p>
              <p>
                – Как ты думаешь, в чем прикол? – Єгор також помітив цю
                цікавинку.
              </p>
              <p>
                – Можу припустити, що це свого роду благодійність зі щедрих
                поривів. А діти ці з бідних сімей, прийшли типу в запорожці (не
                плутай із чудо-автомобілями), – перша аналогія, яка прийшла оце
                із «запорожцями» на весіллі, хоча вона була доволі абстрактна,
                бо я геть не в весільні традиції.
              </p>
              <p>
                Якось Cандхіль так затанцювався, що в пориві запалу прямо з
                кишені в повітря з криком «ваааах» викинув декілька
                десятирупієвих купюр, не перестаючи при цьому танцювати.
                Виглядало дуже ефектно.
              </p>
              <p>
                – Дивись, Єгор, ці діти лише збирають гроші. Тоді віддають їх
                старшим хлопцям, а ті їх акуратно складають в пачки.
              </p>
              <p>– Может, это своего рода мафия? – пожартував Єгор.</p>
              <p>
                Не буду тягнути кота за інтригу – це оплата музикантам. Як на
                весіллі, так і на концертах. У цьому тексті ця інтрига померла
                одразу, хоча для однозначного розвіювання своїх сумнівів я
                просто по душах поговорив про це в Дрездені з хлопаками з Індії,
                через пів року після поїздки.
              </p>
            </Fragment>
          ),
        },
        {
          heading: "Методика перетину вулиці",
          shortText:
            "По перше, ніхто не можеш перейти дорогу у не відведеному для цього місці, тому що спеціально відведених для цього місць, які в буржуазній Європі си називають пішохідні переходи, просто немає, не існує і.(крапка) Як то кажуть...",
          fullText: (
            <Fragment>
              <p>
                По-перше, ніхто не може перейти дорогу у невідведеному для цього
                місці, тому що спеціально відведених для цього місць, які в
                буржуазній Європі си називають пішохідні переходи, просто немає,
                не існує і.(крапка) Як то кажуть не передбачено конструкцією
                (18). Для успішного переходу проїжджої частини в Індії необхідно
                зосередитися, позбутися страху і ступити на дорогу, пильно
                вдивляючись в потік машин, мотоциклів, мопедів, велосипедів,
                моторикш, велорикш, гужового транспорту, ручних тєлєг та інших
                рухомих об’єктів, визначення яким дати доволі складно. Дивитися
                треба так, наче ти «поняв жизнь» і вже давно навчився літати,
                але тобі це більше не цікаво, і тому вирішив іти саме цією
                дорогою. Обі Ван Кенобі має заздрити такому погляду і нервово
                курити в сторонці.
              </p>
              <p>
                Крім пильного погляду, потрібно оцінювати, хто, де і як їде, з
                якого боку буде вас об’їжджати, тому що, нагадаю, літати ви таки
                не вмієте, і це всього лише акторська гра, самоїлюзія. Старатися
                зберігати стабільну швидкість, якщо потрібно зупинитися і
                пропустити транспортні засоби (ТЗ). Це слід робити плавним
                зменшенням швидкості, і ні в якому разі не робити різких рухів,
                зупинок, прискорення тощо, тому що всі перераховані колісні
                тварини можуть злякатися, спрацює захисний рефлекс, і ви можете
                піддатися неприємному нападу з їхнього боку.
              </p>
              <p>
                Ні в якому разі дико не лякайтеся від сигналів і, знову ж таки,
                не робіть різких рухів та стрибків прямо під колеса іншим ТЗ.
                Пам’ятайте: сигнали на дорозі в Індії служать універсальним
                засобом комунікації між учасниками дорожнього руху. Так вони
                позначають себе на дорозі, дають зрозуміти, що йдуть на обгін
                або знаходяться з того чи іншого боку ТЗ, який випереджають.
                Водії зазвичай бачать пішохода і, оцінюючи швидкість його руху,
                об’їжджають його, спереду або ззаду, пригальмовують чи ні.
                Сигнал, поданий вам, знову ж таки, для того щоб попередити вас
                про те, що даний ТЗ на дорозі, можливо, навіть про те, що він
                вас бачить. На жаль, я не вник у всі тонкощі подачі звукових
                сигналів, але їх концепція точно відрізняється від звичної нам.
              </p>
              <p>
                Використовуючи напрацьовану методику, буквально до кінця дня ми
                вже переходили дороги, як місцеві. Фішка в тому, що в якийсь
                момент «ти відчуваєш потік, ти думаєш як потік, ти стаєш
                потоком»; і тоді це виходить дуже природно. Як згадувалося вище,
                пішки пересуватися куди легше проїжджою частиною ніж тротуарами.
              </p>
            </Fragment>
          ),
        },
        {
          heading:
            "6:02. Вірш перший. Святе письмо, від Єгора. «Незабутня поїздка.»",
          shortText:
            "По перше, ніхто не можеш перейти дорогу у не відведеному для цього місці, тому що спеціально відведених для цього місць, які в буржуазній Європі си називають пішохідні переходи, просто немає, не існує і.(крапка) Як то кажуть...",
          fullText: (
            <Fragment>
              <p>
                – Мы еще там, тактически сходили, все покакали по двадцать пять
                раз, но меня это вообще никаким местом не спасло… Ну и
                загрузились. И мы такие радостные чёто в него загрузились, и он
                поехал. И никого нет… А там внизу, как бы первый уровень – это
                сидячие места, а второй уровень – лежачие (53). Он не полностью
                был лежачий. А третий уровень это то, что сверху, он еще был
                загружен. Мы такие думаем: «О, ну норм, поедем в пустом
                автобусе, ну практически… Хоть какая-то развлекуха». Ярик там
                негодовал, что автобус трещит по швам, разваливаеться… Он такой
                внутри сварной… Из… знаешь, просто какая-то палка металлическая
                приварена сюда, а пол – деревянный. И он когда едет, он просто
                хрррсс-хррррсс, – Єгор показав руками знак, як вигиналася
                підлога, щоб візуалізувати свою імітацію хрускоту автобуса. –
                Причем, периодически, когда автобус ехал, там что-то крутилось,
                и, вот представь, если это передаточный какой-то механизм, он
                крутиться, и, частично, бьет по вот этой крышке, – ілюструючи
                все жестами, продовжує він.
              </p>
              <p>
                – То есть ты едешь, и такой БУМ… БУМ… Просто, камон, эта хуйня
                как-то не так должна работать явно. Ну это прям очевидно, что не
                так должно происходить. В общем, он хрустел и ломался. Потом он
                проехал там какое-то расстояние, и, блять… оказалось, что он там
                грузит еще людей. Так этот весь автобус забился еще индусами.
                Нам пришлось позалазить на свои места. И мы вот так вот там
                лежали четырнадцать… четырнадцать…
              </p>
              <p>
                Плюс минус пять, – спробувала зробити акцент на пунктуальність
                Індійського громадського транспорту Соня, але Єгор не
                відволікався.
              </p>
              <p>– Четырнадцять, блять…</p>
              <p>– Плюс минус пять…</p>
              <p>
                – ЧЕТЫРРРРНАДЦАТЬ часссоооов… четырнадцать часов, – Єгор вивів
                інтонаційний пік та звів руки й очі до неба, після чого витримав
                паузу і спокійним, хитруватим тоном продовжив: – От любого
                глотка жидкости… у меня начинался, нaхуй, понос. Мы едем четир…
                ЧЕТЫРНАДЦАТЬ, блять, часов, на этом адском автобусе без, нaхуй,
                вообще амортизаторов. Мне кажеться, веселее ехать, если поезд бы
                сьехал с рельс и ехал бы вот так, он бы мягче бы ехал, чем эта
                хрень.
              </p>
              <p>Прокашлявшись, Єгор продовжив:</p>
              <p>
                – По каким-то адским дорогам, как по не дорогам, а как по…
                деревням. То есть он проежал через какие-то населенные пункты,
                состоящие из отсутствия, нaхуй, населенного пункта и из половины
                человека, там живущего. На улице мы кого то подбирали… Потеряли
                кого-то по дороге, но это не важно.
              </p>
            </Fragment>
          ),
        },
      ],
    },
    footer: {
      heading: "Соціальні мережі",
      social: [
        {
          icon: "facebook",
          link: "https://www.facebook.com/yaroslav.holovenko",
          text: "Фейсбук",
        },
        {
          icon: "instagram",
          link: "https://www.instagram.com/yaroslav.holovenko",
          text: "Інстаграм",
        },
      ],
      copyrightNote: "© 2021 Ярослав Головенько",
    },
  },
  orderPage: {
    orderPreTextHeaderText: "Замовлення книги",
    orderPreText:
      "Отримати цю захоплюючу книгу можна всього за 500 грн заповнивши форму нижче. Після заповнення форми з вами обов'язково зв'яжуться.",
    orderConfirmedText: (
      <Fragment>
        Дякуємо за замовлення книги "В Індію на весілля"!
        <Divider hidden />
        Для завершення замовлення, будь ласка, перекажіть кошти за книгу на
        карту:
        <br />
        <Header>5168 7451 0832 5116</Header>
        <br />
        отримувач Ярослав Головенько
      </Fragment>
    ),
    form: {
      emailField: {
        name: "email",
        title: "Електронна пошта",
        placeholder: "yaruk.fan@toindiafor.wedding",
        errorValidator: (value) => {
          if (!value) {
            return "Будь ласка введіть свою адресу електронної пошти";
          }
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            return "Будь ласка введіть дійсну адресу електронної пошти";
          }
          return undefined;
        },
      },
      nameField: {
        name: "name",
        title: "Ім‘я",
        placeholder: "Прізвище Ім'я",
        errorValidator: (value) => {
          if (!value) {
            return "Будь ласка введіть своє ім‘я";
          }
          return undefined;
        },
      },
      phoneField: {
        name: "phone",
        title: "Номер телефону",
        placeholder: "+38-XXX-XXXXXXX",
        errorValidator: (value) => {
          if (!value) {
            return "Будь ласка введіть свій номер телефону";
          }
          return undefined;
        },
      },
      cityField: {
        name: "city",
        title: "Деталі доставки: місто",
        placeholder: "",
        errorValidator: (value) => {
          if (!value) {
            return "Будь ласка введіть назву міста";
          }
          return undefined;
        },
      },
      postalField: {
        name: "postal",
        title: "Деталі доставки: відділення нової пошти",
        placeholder: "",
        errorValidator: (value) => {
          if (!value) {
            return "Будь ласка введіть номер відділення Нової Пошти";
          }
          return undefined;
        },
      },
      commentField: {
        name: "comment",
        title: "Коментар (опціонально)",
        placeholder: "", //"Прохання, побажання",
        errorValidator: (value) => {
          // It's an optional field
          return undefined;
        },
      },
      promoField: {
        name: "promo",
        title: "Промо код (опціонально)",
        placeholder: "",
        errorValidator: (value) => {
          // It's an optional field
          return undefined;
        },
      },
      sendButton: {
        text: "Замовити!",
      },
    },
  },
};
