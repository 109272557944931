// Blueprint component for routing all QR source media pages. Used as a template by the QR generator python script
import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import MediaPage from "./MediaPage";

class MediaRouterTemplate extends React.Component {
  render() {
    const MEDIA_PAGES_COMPONENTS_LIST = null;

    return (
      <Fragment>
        <Fragment>
          <Route
            path={"/media/f1450306517624a57eafbbf8ed995985"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/f1450306517624a57eafbbf8ed995985.jpg")}
              />
            )}
          />
          <Route
            path={"/media/cfb8bb3395cef9f3b3586c2516e966a6"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/cfb8bb3395cef9f3b3586c2516e966a6.jpg")}
              />
            )}
          />
          <Route
            path={"/media/7d539aab6caf7b25015987f04f50d931"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/7d539aab6caf7b25015987f04f50d931.jpg")}
              />
            )}
          />
          <Route
            path={"/media/35c901b775c2206dbd0a2ad35ec92d0e"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/35c901b775c2206dbd0a2ad35ec92d0e.txt")}
              />
            )}
          />
          <Route
            path={"/media/a6b6ca548431e1202e4248ccf28fd4ad"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/a6b6ca548431e1202e4248ccf28fd4ad.jpg")}
              />
            )}
          />
          <Route
            path={"/media/6fcc61d8f99f34496d808c1c10676cf0"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/6fcc61d8f99f34496d808c1c10676cf0.jpg")}
              />
            )}
          />
          <Route
            path={"/media/d676ba06eb8a6e41b3267d13f963044b"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/d676ba06eb8a6e41b3267d13f963044b.jpg")}
              />
            )}
          />
          <Route
            path={"/media/9f6aa814d1fd5c1e8065531d1193c789"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/9f6aa814d1fd5c1e8065531d1193c789.jpg")}
              />
            )}
          />
          <Route
            path={"/media/2e3aa43dea0712c534e6cff2bf04e5c9"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/2e3aa43dea0712c534e6cff2bf04e5c9.jpg")}
              />
            )}
          />
          <Route
            path={"/media/a4300bfbc02f3d4a1e47d4caf91fd29e"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/a4300bfbc02f3d4a1e47d4caf91fd29e.jpg")}
              />
            )}
          />
          <Route
            path={"/media/562a8f3ee4e5c94981e2259e3253beb1"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/562a8f3ee4e5c94981e2259e3253beb1.jpg")}
              />
            )}
          />
          <Route
            path={"/media/467a4b87a306f81d7dff541bbf433377"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/467a4b87a306f81d7dff541bbf433377.jpg")}
              />
            )}
          />
          <Route
            path={"/media/f947c2b6601d33a6328720013f53a308"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/f947c2b6601d33a6328720013f53a308.jpg")}
              />
            )}
          />
          <Route
            path={"/media/f11177d2ec63d995fb4ac628e0d782df"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/f11177d2ec63d995fb4ac628e0d782df.jpg")}
              />
            )}
          />
          <Route
            path={"/media/8e97382f2e6a1fe660ddede803955d22"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/8e97382f2e6a1fe660ddede803955d22.jpg")}
              />
            )}
          />
          <Route
            path={"/media/009d30980aa408f62a12e0e7e7acbd17"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/009d30980aa408f62a12e0e7e7acbd17.jpg")}
              />
            )}
          />
          <Route
            path={"/media/0764924ee9c3cff6d74cd34f5d986d6b"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/0764924ee9c3cff6d74cd34f5d986d6b.jpg")}
              />
            )}
          />
          <Route
            path={"/media/4dd9845b20c0205b635abb2134f5dfe7"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/4dd9845b20c0205b635abb2134f5dfe7.jpg")}
              />
            )}
          />
          <Route
            path={"/media/b4526e59a534303ef4417f74f925ab91"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/b4526e59a534303ef4417f74f925ab91.jpg")}
              />
            )}
          />
          <Route
            path={"/media/59dea06d5a405f950b26478b3138b519"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/59dea06d5a405f950b26478b3138b519.jpg")}
              />
            )}
          />
          <Route
            path={"/media/7debb56a86892babf3ca06f0a8a0a15a"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/7debb56a86892babf3ca06f0a8a0a15a.jpg")}
              />
            )}
          />
          <Route
            path={"/media/1a6f00ceda41c4f90100dadd6aa5a20c"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/1a6f00ceda41c4f90100dadd6aa5a20c.jpg")}
              />
            )}
          />
          <Route
            path={"/media/a54666d8d67223511976a5499bd0ed75"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/a54666d8d67223511976a5499bd0ed75.jpg")}
              />
            )}
          />
          <Route
            path={"/media/e3a98ec981a4c757b41be4f2a3609d68"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/e3a98ec981a4c757b41be4f2a3609d68.jpg")}
              />
            )}
          />
          <Route
            path={"/media/584a15a90f2f959d0703594ad447ae93"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/584a15a90f2f959d0703594ad447ae93.jpg")}
              />
            )}
          />
          <Route
            path={"/media/361047157d71808f6f9eb3e6305a2889"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/361047157d71808f6f9eb3e6305a2889.jpg")}
              />
            )}
          />
          <Route
            path={"/media/199780d32cc00f378390716db544294a"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/199780d32cc00f378390716db544294a.jpg")}
              />
            )}
          />
          <Route
            path={"/media/c65a9f16901a3b0c11adc5a52802df7e"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/c65a9f16901a3b0c11adc5a52802df7e.jpg")}
              />
            )}
          />
          <Route
            path={"/media/8b86f47fdc4877d122f88ef001d9fa0a"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/8b86f47fdc4877d122f88ef001d9fa0a.jpg")}
              />
            )}
          />
          <Route
            path={"/media/422b61c1a5f06dcbf774e9513203ea82"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/422b61c1a5f06dcbf774e9513203ea82.jpg")}
              />
            )}
          />
          <Route
            path={"/media/66bc172b52faf05d4dc19e1532b65030"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/66bc172b52faf05d4dc19e1532b65030.png")}
              />
            )}
          />
          <Route
            path={"/media/1bffe36cf6c46c7b40d88acaa7a46f84"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/1bffe36cf6c46c7b40d88acaa7a46f84.jpg")}
              />
            )}
          />
          <Route
            path={"/media/46d47cd75b4bf025007567c7795cd441"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/46d47cd75b4bf025007567c7795cd441.jpg")}
              />
            )}
          />
          <Route
            path={"/media/08c3734568f63ef539c33e4474d6c085"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/08c3734568f63ef539c33e4474d6c085.jpg")}
              />
            )}
          />
          <Route
            path={"/media/8f563c4da2d643db5d8755fc2e1fdaa6"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/8f563c4da2d643db5d8755fc2e1fdaa6.jpg")}
              />
            )}
          />
          <Route
            path={"/media/ea4959eb64a1f09be580d950964f3843"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/ea4959eb64a1f09be580d950964f3843.png")}
              />
            )}
          />
          <Route
            path={"/media/f6368010ee1f7169b0dc34ffd2386568"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/f6368010ee1f7169b0dc34ffd2386568.jpg")}
              />
            )}
          />
          <Route
            path={"/media/528556218eedde4d6b794a3416ac8e7b"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/528556218eedde4d6b794a3416ac8e7b.JPG")}
              />
            )}
          />
          <Route
            path={"/media/a515855799ddbda08bc99fc2ce87fa79"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/a515855799ddbda08bc99fc2ce87fa79.jpg")}
              />
            )}
          />
          <Route
            path={"/media/de2f501f92f3278c89975d88046aa687"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/de2f501f92f3278c89975d88046aa687.jpg")}
              />
            )}
          />
          <Route
            path={"/media/6ff43a9c893f569f5e21180998ad0db6"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/6ff43a9c893f569f5e21180998ad0db6_.png")}
              />
            )}
          />
          <Route
            path={"/media/7279e8697839520844b82317c8fae6a9"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/7279e8697839520844b82317c8fae6a9.png")}
              />
            )}
          />
          <Route
            path={"/media/1484b61b9d820a9898790d7b63b24a82"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/1484b61b9d820a9898790d7b63b24a82.tif")}
              />
            )}
          />
          <Route
            path={"/media/e8b1fdd26272b298c9f71fb1b1971908"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/e8b1fdd26272b298c9f71fb1b1971908.jpg")}
              />
            )}
          />
          <Route
            path={"/media/10f418b5a3a232611a116002bd0a19de"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/10f418b5a3a232611a116002bd0a19de.jpg")}
              />
            )}
          />
          <Route
            path={"/media/27f5be9b8425ad177e003696e9e3c4ec"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/27f5be9b8425ad177e003696e9e3c4ec_.jpg")}
              />
            )}
          />
          <Route
            path={"/media/bb3e7acf9d8474b5672b2aa6f27bf644"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/bb3e7acf9d8474b5672b2aa6f27bf644.png")}
              />
            )}
          />
          <Route
            path={"/media/2140524a52f9b9eaff1a066b8b5fe0ee"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/2140524a52f9b9eaff1a066b8b5fe0ee.jpg")}
              />
            )}
          />
          <Route
            path={"/media/be6b6efffc993f38eedc9a50b6eb5e48"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/be6b6efffc993f38eedc9a50b6eb5e48.jpg")}
              />
            )}
          />
          <Route
            path={"/media/ee8464c4dd21a2fa3f83386ebdfab6a5"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/ee8464c4dd21a2fa3f83386ebdfab6a5.jpg")}
              />
            )}
          />
          <Route
            path={"/media/7fef1d0f9de33a418bd6b97f3b6142e3"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/7fef1d0f9de33a418bd6b97f3b6142e3.JPG")}
              />
            )}
          />
          <Route
            path={"/media/360d99a5eedfcc311bfac3dd23a8bcdb"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/360d99a5eedfcc311bfac3dd23a8bcdb.jpg")}
              />
            )}
          />
          <Route
            path={"/media/32b45012b2ba771193da0aaed94102d8"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/32b45012b2ba771193da0aaed94102d8_.png")}
              />
            )}
          />
          <Route
            path={"/media/faf6e1864fce318ad9040b26140cc01d"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/faf6e1864fce318ad9040b26140cc01d.jpg")}
              />
            )}
          />
          <Route
            path={"/media/5301bfa3fe54bb793c685437da49ac42"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/5301bfa3fe54bb793c685437da49ac42.jpg")}
              />
            )}
          />
          <Route
            path={"/media/9694c0d6b384d8aa647c840e4c8a3435"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/9694c0d6b384d8aa647c840e4c8a3435.jpg")}
              />
            )}
          />
          <Route
            path={"/media/e93144540f4aa366aefe80b13d2ebbb9"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/e93144540f4aa366aefe80b13d2ebbb9.jpg")}
              />
            )}
          />
          <Route
            path={"/media/b25030c176614061fb2bbfd8194af2e0"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/b25030c176614061fb2bbfd8194af2e0.jpg")}
              />
            )}
          />
          <Route
            path={"/media/bee0c77ef4404efae1127c10140867a5"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/bee0c77ef4404efae1127c10140867a5.jpg")}
              />
            )}
          />
          <Route
            path={"/media/41e38d0971d62982b4d45941b790d8d2"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/41e38d0971d62982b4d45941b790d8d2.jpg")}
              />
            )}
          />
          <Route
            path={"/media/ab1239c3bc437d48321a64e6b5e5ab7f"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/ab1239c3bc437d48321a64e6b5e5ab7f.jpg")}
              />
            )}
          />
          <Route
            path={"/media/38fc9331271e16f0e5586a0fc993be00"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/38fc9331271e16f0e5586a0fc993be00.jpg")}
              />
            )}
          />
          <Route
            path={"/media/ec4f29cdbd07a997b228d05ad9e4cea1"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/ec4f29cdbd07a997b228d05ad9e4cea1.jpg")}
              />
            )}
          />
          <Route
            path={"/media/30776ac5253ba03390b0a2fcce9c7026"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/30776ac5253ba03390b0a2fcce9c7026.jpg")}
              />
            )}
          />
          <Route
            path={"/media/91446ac941ef79c7b553ed9285ef2f73"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/91446ac941ef79c7b553ed9285ef2f73.jpg")}
              />
            )}
          />
          <Route
            path={"/media/88fda95e79c30790d3ebccb26a1e3d0a"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/88fda95e79c30790d3ebccb26a1e3d0a.jpg")}
              />
            )}
          />
          <Route
            path={"/media/17da6c3d752336c5d3b5144e4ad2a0a2"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/17da6c3d752336c5d3b5144e4ad2a0a2.jpg")}
              />
            )}
          />
          <Route
            path={"/media/bdb56d515bffc5daec0e555677d0b860"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/bdb56d515bffc5daec0e555677d0b860.jpg")}
              />
            )}
          />
          <Route
            path={"/media/679e19234b295ee432a3920c30fda6ac"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/679e19234b295ee432a3920c30fda6ac.jpg")}
              />
            )}
          />
          <Route
            path={"/media/9485552ad99c3647741e813f265ac3a8"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/9485552ad99c3647741e813f265ac3a8.jpg")}
              />
            )}
          />
          <Route
            path={"/media/b0b3ae6a18c02a11ad60d3b1bb3a15d0"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/b0b3ae6a18c02a11ad60d3b1bb3a15d0.jpg")}
              />
            )}
          />
          <Route
            path={"/media/a131bb26f877f8cb957fe966bbb2dab4"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/a131bb26f877f8cb957fe966bbb2dab4.jpg")}
              />
            )}
          />
          <Route
            path={"/media/d6a801b426aa7ec93bd5a4730dedc94d"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/d6a801b426aa7ec93bd5a4730dedc94d.jpg")}
              />
            )}
          />
          <Route
            path={"/media/7174a48580770b17c9eec49e3a36a386"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/7174a48580770b17c9eec49e3a36a386.jpg")}
              />
            )}
          />
          <Route
            path={"/media/d4497bc82593e54e0c3f98bb1d8922fc"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/d4497bc82593e54e0c3f98bb1d8922fc.jpg")}
              />
            )}
          />
          <Route
            path={"/media/918ad8cfafe03cf711df68bf8bc93bad"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/918ad8cfafe03cf711df68bf8bc93bad.jpg")}
              />
            )}
          />
          <Route
            path={"/media/e71c5c81a2c3f55592334884291add74"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/e71c5c81a2c3f55592334884291add74.jpg")}
              />
            )}
          />
          <Route
            path={"/media/af669c2df8778df0e8a283c3686504ef"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/af669c2df8778df0e8a283c3686504ef.jpg")}
              />
            )}
          />
          <Route
            path={"/media/c47b300aee4e712580f9ec8be0907186"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/c47b300aee4e712580f9ec8be0907186.jpg")}
              />
            )}
          />
          <Route
            path={"/media/7b716134f85372d683d3fbc2ab4396aa"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/7b716134f85372d683d3fbc2ab4396aa.jpg")}
              />
            )}
          />
          <Route
            path={"/media/01e9ed065629c13cdd69b6115ebe39e9"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/01e9ed065629c13cdd69b6115ebe39e9.jpg")}
              />
            )}
          />
          <Route
            path={"/media/cfaa1da9305a58d5186a9bc6e1f9dbbc"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/cfaa1da9305a58d5186a9bc6e1f9dbbc.jpg")}
              />
            )}
          />
          <Route
            path={"/media/fbd621e9a326c67b2ede3c7012a6109d"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/fbd621e9a326c67b2ede3c7012a6109d.jpg")}
              />
            )}
          />
          <Route
            path={"/media/4f3f9cb98a3607a6ee131c36b1840241"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/4f3f9cb98a3607a6ee131c36b1840241.jpg")}
              />
            )}
          />
          <Route
            path={"/media/d781dafc2d5e8d11687e529b0868ea4f"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/d781dafc2d5e8d11687e529b0868ea4f.jpg")}
              />
            )}
          />
          <Route
            path={"/media/e29979660f9a819d73e7c66a4f350536"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/e29979660f9a819d73e7c66a4f350536.jpg")}
              />
            )}
          />
          <Route
            path={"/media/a03b601751a0b0d53d298a9f7c1c9225"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/a03b601751a0b0d53d298a9f7c1c9225.jpg")}
              />
            )}
          />
          <Route
            path={"/media/f640aad58ca881c25d13e44ede42b9b1"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/f640aad58ca881c25d13e44ede42b9b1.jpg")}
              />
            )}
          />
          <Route
            path={"/media/06e0434c82d27779aa3b8f01c565b87c"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/06e0434c82d27779aa3b8f01c565b87c.jpg")}
              />
            )}
          />
          <Route
            path={"/media/3d3e020546b954fe9362db781abce851"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/3d3e020546b954fe9362db781abce851.jpg")}
              />
            )}
          />
          <Route
            path={"/media/f9f682e62da5249cd50565a6306ad4c2"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/f9f682e62da5249cd50565a6306ad4c2.jpg")}
              />
            )}
          />
          <Route
            path={"/media/99dbcf8cc22fe82ce5451f205cc4447a"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/99dbcf8cc22fe82ce5451f205cc4447a.jpg")}
              />
            )}
          />
          <Route
            path={"/media/0e41427fa20f298976c5f26673cb8467"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/0e41427fa20f298976c5f26673cb8467.jpg")}
              />
            )}
          />
          <Route
            path={"/media/639c5d31c778a7366e73685dea84147d"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/639c5d31c778a7366e73685dea84147d.jpg")}
              />
            )}
          />
          <Route
            path={"/media/afa97ef1248eb9089b694b9727e47652"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/afa97ef1248eb9089b694b9727e47652.jpg")}
              />
            )}
          />
          <Route
            path={"/media/3eaf3e845285f2b2228e598cd6d512fc"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/3eaf3e845285f2b2228e598cd6d512fc.jpg")}
              />
            )}
          />
          <Route
            path={"/media/a92493e70d90f2d109d5831a6a54d931"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/a92493e70d90f2d109d5831a6a54d931.jpg")}
              />
            )}
          />
          <Route
            path={"/media/8b41d42570561ecee5c798bc15046194"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/8b41d42570561ecee5c798bc15046194.jpg")}
              />
            )}
          />
          <Route
            path={"/media/0e62f05d062fb14352ada3736db60fe5"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/0e62f05d062fb14352ada3736db60fe5.jpg")}
              />
            )}
          />
          <Route
            path={"/media/28bbf0cd5a301205787c3f73f340eb69"}
            component={() => (
              <MediaPage
                mediaSrc={require("./resources/media/28bbf0cd5a301205787c3f73f340eb69.jpg")}
              />
            )}
          />
        </Fragment>
      </Fragment>
    );
  }
}

export default MediaRouterTemplate;
