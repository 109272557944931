// Header action types
export const headerActionTypes = {
  OPEN_MOBILE_SIDEBAR: "OPEN_MOBILE_SIDEBAR",
  CLOSE_MOBILE_SIDEBAR: "CLOSE_MOBILE_SIDEBAR",
};

/**
 * Opens mobile sidebar.
 * @returns {{payload: null, type: string}} Empty payload.
 */
export const openMobileSidebar = () => {
  return {
    type: headerActionTypes.OPEN_MOBILE_SIDEBAR,
    payload: null,
  };
};

/**
 * Closes mobile sidebar.
 * @returns {{payload: null, type: string}} Empty payload.
 */
export const closeMobileSidebar = () => {
  return {
    type: headerActionTypes.CLOSE_MOBILE_SIDEBAR,
    payload: null,
  };
};
