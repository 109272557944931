// Reviews section of the landing page
import React, { Fragment } from "react";

import "./reviewsSection.css";
import ReviewCard from "../elements/ReviewCard";
import { Card } from "semantic-ui-react";
import ResponsiveContainer from "../../shared components/ResponsiveContainer";
import FlowersBackground from "../../shared components/FlowersBackground";
import HeaderBubble from "../elements/HeaderBubble";
import Divider from "semantic-ui-react/dist/commonjs/elements/Divider";
import ExcerptCard from "../elements/ExcerptCard";
import AppConfig from "../../../AppConfig";

const config = AppConfig.landingPage.excerptsSection;

class ExcerptsSection extends React.Component {
  renderExcerptsGrid = () => {
    const reviewCards = config.excerpts.map((excerptData, index) => {
      return <ExcerptCard key={index} {...excerptData} />;
    });

    return (
      <Card.Group centered stackable itemsPerRow={2}>
        {reviewCards}
      </Card.Group>
    );
  };

  render() {
    return (
      <Fragment>
        <div className="reviews-section">
          <FlowersBackground />
          <div className="reviews-section-padding">
            <ResponsiveContainer>
              <HeaderBubble text={config.heading} />
              <Divider hidden className="thin-horizontal-divider" />
              {this.renderExcerptsGrid()}
            </ResponsiveContainer>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ExcerptsSection;
